<template>
  <div>
    <van-cell :title="'开关'" >
      <template #right-icon>
        <van-switch v-model="form.open"  size="24px" @change="onChange" />
      </template>
    </van-cell>
    <div >

    <div>
      <van-divider content-position="left">时段一</van-divider>
      <van-cell :title="'开始时间'" :value="form.startTime" is-link @click="showStartPicker=true"/>
      <van-popup v-model="showStartPicker" round position="bottom">
        <van-datetime-picker
            v-model="startTimeS"
            type="time"
            title="选择时间"
            @confirm="onConfirmStartTime"
            @cancel="showStartPicker=false"
        />
      </van-popup>

      <van-cell :title="'结束时间'" :value="form.endTime" is-link @click="showEndPicker=true" />
      <van-popup v-model="showEndPicker" round position="bottom">
        <van-datetime-picker
            v-model="endTimeS"
            type="time"
            title="选择时间"
            @confirm="onConfirmEndTime"
            @cancel="showEndPicker=false"
        />
      </van-popup>
    </div>

    <div>
      <van-divider content-position="left">时段二</van-divider>
      <van-cell :title="'开始时间'" :value="form.startTime2" is-link @click="showStartPicker2=true"/>
      <van-popup v-model="showStartPicker2" round position="bottom">
        <van-datetime-picker
            v-model="startTimeS2"
            type="time"
            title="选择时间"
            @confirm="onConfirmStartTime2"
            @cancel="showStartPicker2=false"
        />
      </van-popup>

      <van-cell :title="'结束时间'" :value="form.endTime2" is-link @click="showEndPicker2=true" />
      <van-popup v-model="showEndPicker2" round position="bottom">
        <van-datetime-picker
            v-model="endTimeS2"
            type="time"
            title="选择时间"
            @confirm="onConfirmEndTime2"
            @cancel="showEndPicker2=false"
        />
      </van-popup>
    </div>
      <div v-if="form.weekRepeat">
        <van-divider content-position="left">重复周期</van-divider>
        <van-cell :title="'重复'" :value="weekText" is-link @click="onShowWeekPicker" />
        <van-popup v-model="showWeekPicker" round position="bottom">
          <div>
              <van-cell-group>
                <van-cell

                    v-for="(item) in weekList"
                    clickable
                    @click="weekValue[item['key']] = !weekValue[item['key']]"
                    :key="item['key']"
                    :title="item['name']"
                >
                  <template #right-icon>
                    <van-checkbox v-model="weekValue[item['key']]" :name="item['key']"  />
                  </template>
                </van-cell>
              </van-cell-group>
            <div style="padding:20px 20px 50px 20px">
              <van-row gutter="20">
                <van-col span="12"><van-button block type="default" @click="onHideWeekPicker(false)">取消</van-button></van-col>
                <van-col span="12"><van-button block type="primary" @click="onHideWeekPicker(true)">确认</van-button></van-col>
              </van-row>
            </div>



            </div>
        </van-popup>
      </div>
    </div>
    <div class="van-cell-div-labelc">
      {{this.$route.params.label}}
    </div>
    <div style="margin:0.26667rem 0.42667rem">

      <van-row  gutter="20">
        <van-col span="8"><van-button plain type="danger" @click="back" round block>返 回</van-button></van-col>
        <van-col span="16"><van-button round block type="info" native-type="submit" @click="submit">确认修改</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import {Cell, CellGroup, Switch, Divider, Button, DatetimePicker, Popup, Notify,Checkbox,Row,Col} from 'vant';
import {updateSetting} from "@/api/devicesetting";
import {getImei} from "@/util/session-storage-utils";

export default {
  components: {
    [Cell.name]:Cell,
    [CellGroup.name]:CellGroup,
    [Switch.name]:Switch,
    [Button.name]:Button,
    [DatetimePicker.name]:DatetimePicker,
    [Popup.name]:Popup,
    [Notify.name]:Popup,
    [Divider.name]:Divider,
    [Row.name]:Row,
    [Col.name]:Col,
    [Checkbox.name]:Checkbox
  },
  props:{
  },
  name: "index",
  data(){
    return {
      showStartPicker:false,
      showEndPicker:false,
      showStartPicker2:false,
      showEndPicker2:false,
      showWeekPicker:false,
      startTimeS: this.$route.params.startTime,
      endTimeS:this.$route.params.endTime,
      startTimeS2: this.$route.params.startTime2,
      endTimeS2:this.$route.params.endTime2,
      form:this.$route.params,
      isChange:false,
      weekValue:{},
      weekList:[{name:"周一",key:"monday"}
      ,{name:"周二",key:"tuesday"}
      ,{name:"周三",key:"wednesday"}
      ,{name:"周四",key:"thursday"}
      ,{name:"周五",key:"friday"}
      ,{name:"周六",key:"saturday"}
      ,{name:"周日",key:"sunday"}
      ]
    }
  },
  computed:{
    weekText(){
      let text = "";
      for (const key in this.weekList) {
        let week = this.weekList[key];
        text += this.getWeekText(this.form[week.key],week.name)
      }
      return text
    }
  },
  methods:{
    onShowWeekPicker(){
      this.showWeekPicker = true;
      this.weekValue = {...this.form};
    },
    onHideWeekPicker(confirm){
      this.showWeekPicker = false;
      if(!confirm){
        return
      }
      this.form = {...this.form,...this.weekValue}

    },
    onChange(value){
      this.$emit('change',value)
    },
    onConfirmStartTime(value){
      this.showStartPicker=false;
      this.form.startTime=value;
    },
    onConfirmEndTime(value){
      this.showEndPicker=false;
      this.form.endTime=value;
    },
    onConfirmStartTime2(value){
      this.showStartPicker2=false;
      this.form.startTime2=value;
    },
    onConfirmEndTime2(value){
      this.showEndPicker2=false;
      this.form.endTime2=value;
    },
    back(){
      window.history.back();
    },
    submit(){
      let req = {};
      if(this.form.weekRepeat){
        let weekSet = false;
        for (const key in this.weekList) {
          let week = this.weekList[key];
          if(this.form[week.key]){
            weekSet = true;
          }
        }
        if(!weekSet){
          Notify({ type: 'danger', message: '请选择重复时间',duration:1000 })
          return;
        }
      }
      let params = {open:
        this.form.open,
        startTime:this.form.startTime,
        endTime:this.form.endTime,
        startTime2:this.form.startTime2,
        endTime2:this.form.endTime2,

      };
      if(this.form.weekRepeat){
        params = {...params,
          monday:this.form.monday,
          tuesday:this.form.tuesday,
          wednesday:this.form.wednesday,
          thursday:this.form.thursday,
          friday:this.form.friday,
          saturday:this.form.saturday,
          sunday:this.form.sunday,}
      }
      req[this.$route.params.id] = params;
      this.isChange = true;
      updateSetting(getImei(),req)
          .then(() => {
                Notify({ type: 'success', message: '数据更新成功',duration:1000 })
                this.$router.go(-1);
              }
          )
    },
    getWeekText(bool,text){
      return bool?text + " ":""
    }
  },
  created(){
    document.title = this.$t("设备设置");
  },
  watch:{
    // checked(){
    //   this.checked = this.checked;
    // }
  }
  // ,beforeRouteLeave(to, from, next){
  //   // 设置下一个路由的 meta
  //   to.meta.keepAlive = !this.isChange;
  //   next();
  // }
}
</script>
<style scoped src="../../../../src/css/setting/global.css"/>
